var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("List Transaksi")]),_c('span',[_vm._v("Rp. "+_vm._s(this.currencyFormat(this.totalAmount)))])]),_c('div',{staticClass:"panel-body p-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10",class:{
              'col-12': !_vm.filter.date,
            }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.date),expression:"filter.date"}],staticClass:"form-control mb-2",attrs:{"type":"date"},domProps:{"value":(_vm.filter.date)},on:{"change":_vm.fetchData,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filter, "date", $event.target.value)}}})]),(_vm.filter.date)?_c('div',{staticClass:"col-2"},[_c('button',{staticClass:"btn btn-success btn-block",attrs:{"type":"button","to":"/data-transaksi/create","disabled":_vm.loadExcel},on:{"click":function($event){return _vm.downloadExcel()}}},[(_vm.loadExcel)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),(!_vm.loadExcel)?_c('i',{staticClass:"fa fa-file-excel d-md-none"}):_vm._e(),(!_vm.loadExcel)?_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Export Excel")]):_vm._e()])]):_vm._e()]),_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"search-options":{
            enabled: true,
            trigger: 'keyup',
            placeholder: 'Pencarian',
          },"pagination-options":{
            enabled: true,
            perPageDropdownEnabled: true,
            perPageDropdown: [10, 20, 30, 40, 50, 100],
            dropdownAllowAll: false,
            position: 'bottom',
            rowsPerPageLabel: '',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: '',
          },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[_c('button',{staticClass:"btn btn-sm btn-circle btn-icon btn-success m-r-5",class:{
                  'btn-grey': props.row.transaction_status == '0',
                },attrs:{"disabled":props.row.transaction_status == '1' && _vm.userLevel != 'admin'},on:{"click":function($event){return _vm.confirmTransaction(
                    props.row.transaction_id,
                    props.row.transaction_status
                  )}}},[_c('i',{staticClass:"fa fa-check",class:{
                    'fa-exclamation': props.row.transaction_status == '0',
                  }})])]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('b-dropdown',{attrs:{"variant":"none","no-caret":true,"toggle-class":"btn btn-circle btn-icon btn-info  m-r-5"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"fa fa-print"})]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.printSKRD(props.row.transaction_id)}}},[_vm._v("Print SKRD")]),(props.row.transaction_status == '1')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.printSSRD(props.row.transaction_id)}}},[_vm._v("Print SSRD")]):_vm._e(),(props.row.transaction_status == '1')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.printBoth(props.row.transaction_id)}}},[_vm._v("Print SKRD & SSRD")]):_vm._e()],2),_c('router-link',{staticClass:"btn btn-circle btn-icon btn-success m-r-5",attrs:{"to":'/data-transaksi/detail/' + props.row.transaction_id}},[_c('i',{staticClass:"fa fa-search"})]),(_vm.userLevel == 'admin')?_c('button',{staticClass:"btn btn-circle btn-icon btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.transaction_id)}}},[_c('i',{staticClass:"fa fa-trash-alt"})]):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': this.isLoading }})]),_c('router-link',{staticClass:"btn btn-primary mr-2",attrs:{"to":"/data-transaksi/create"}},[_c('i',{staticClass:"fa fa-plus d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Transaksi Baru")])])],1)])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }